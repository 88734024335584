import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeImgSize from "rehype-img-size";
import EditStoryForm from "../components/Editor/EditStoryForm";

export default function StoryPage(admin, storyId) {
  console.log("Story ID:", storyId);
  const [isEditing, setIsEditing] = useState(false);
  const [editedStory, setEditedStory] = useState(null);
  const { newsStoryId } = useParams();
  const [story, setStory] = useState(null);

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const storyRef = doc(db, "newsStories", newsStoryId);
        const storySnapshot = await getDoc(storyRef);

        if (storySnapshot.exists()) {
          setStory({ ...storySnapshot.data(), id: storySnapshot.id });
          setEditedStory({ ...storySnapshot.data(), id: storySnapshot.id });
          console.log("Fetched story:", storySnapshot);
        } else {
          console.error("Story not found!");
        }
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchStory();
  }, [newsStoryId]);

  const toggleEdit = () => {
    if (isEditing) {
      setEditedStory({ ...story });
    }
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    try {
      const storyRef = doc(db, "newsStories", editedStory.id);
      await updateDoc(storyRef, {
        title: editedStory.title,
        content: editedStory.content,
      });
      setStory(editedStory);
      toggleEdit();
    } catch (error) {
      console.error("Error updating story:", error);
    }
  };

  if (!story) {
    return <div className="p-6 text-goblin-gold">Loading story...</div>; // Or a goblin-themed loading animation
  }
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <article className="story-page p-6 bg-deep-shadow rounded-lg shadow-md">
      {admin && isEditing ? (
        <EditStoryForm story={story} onSave={handleSave} />
      ) : (
        <div>
          <h1 className="text-3xl font-bold text-goblin-gold mb-4">
            {story.title}
          </h1>
          <h2 className="">By {story.author}</h2>
          <p className="text-mold-purple text-lg">
            {new Date(story.dateCreated.seconds * 1000).toLocaleDateString(
              "en-US",
              options
            )}
          </p>
          <ReactMarkdown
            className="text-bone-white text-lg"
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeImgSize]}
          >
            {story.content}
          </ReactMarkdown>
        </div>
      )}
      <button
        onClick={toggleEdit}
        className="px-4 py-2 bg-goblin-gold text-shadow-black rounded hover:bg-gold-glitter"
      >
        {isEditing ? "Discard Changes!" : "Edit this Mess"}
      </button>
    </article>
  );
}
