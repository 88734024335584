import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import ContentArea from "../components/ContentArea";

export default function CategoryPage() {
  const { categoryId } = useParams();
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const categoryRef = doc(db, "categories", categoryId);
        const categorySnapshot = await getDoc(categoryRef);
        if (categorySnapshot.exists()) {
          setCategory(categorySnapshot.data());
        } else {
          console.error("Category not found!");
          setCategory({ name: "Category Not Found" });
        }
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    fetchCategory();
  }, [categoryId]);

  return (
    <div className="category-page p-6 bg-cave-grey rounded-lg shadow-md">
      <h1 className="text-3xl font-bold text-goblin-gold mb-4">
        {category ? category.name : "Loading..."}
      </h1>
      <ContentArea category={categoryId} />
    </div>
  );
}
