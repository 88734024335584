import React, { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import { storage } from "../../firebase"; // Import your firebase config
import "../../styles/popup.css";

function ImageGallery() {
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false); // State for popup visibility

  useEffect(() => {
    const fetchImages = async () => {
      console.log("Fetching images..."); // Start of fetching process
      try {
        const storageRef = ref(storage, "articleImages");
        console.log("Storage reference created:", storageRef);

        const result = await listAll(storageRef);
        console.log("ListAll result:", result);

        const imageDataPromises = result.items.map(async (imageRef) => {
          console.log("Metadata fetched:", await getMetadata(imageRef));
        });

        const imageData = await Promise.all(imageDataPromises);

        // Filter out any null values (from errors)
        const validImageData = imageData.filter((data) => data !== null);

        console.log("ImageData before sorting:", validImageData);

        // Sort images by dateAdded (most recent first)
        validImageData.sort(
          (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
        );

        console.log("Setting images state:", validImageData);
        setImages(validImageData);
      } catch (error) {
        console.error("Error fetching images: ", error);
      }
    };

    fetchImages();
  }, []);

  const copyImageUrl = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("Image URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div>
      <button onClick={() => setIsOpen(true)}>Open Image Gallery</button>

      {isOpen && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={() => setIsOpen(false)}>
              CLOSE
            </span>
            <h2>Image Gallery</h2>

            <div
              className="image-grid"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              {images.map((image, index) => (
                <div key={index} className="image-container">
                  <img
                    src={image.url}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                  />
                  <button onClick={() => copyImageUrl(image.url)}>
                    Copy URL
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default ImageGallery;
