import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

import ImageUpload from "../components/Editor/ImageUpload";
import ImageGallery from "../components/Editor/ImageGallery";

export default function EditorPortal() {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [isBreaking, setIsBreaking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [breakingNews, setBreakingNews] = useState([]);

  // Function to fetch breaking news
  const fetchBreakingNews = async () => {
    const querySnapshot = await getDocs(collection(db, "breakingNews"));
    const breakingNewsList = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setBreakingNews(breakingNewsList);
  };
  const handleToggleBreakingNews = async (newsId, newIsActiveStatus) => {
    try {
      const newsRef = doc(db, "breakingNews", newsId);
      await updateDoc(newsRef, { isActive: newIsActiveStatus });
      fetchBreakingNews(); // Refresh the list after updating
    } catch (error) {
      console.error("Fire and brimstone! Error toggling breaking news:", error);
      alert(
        "Failed to toggle breaking news. Did you offer the proper sacrifice to the code goblins?"
      );
    }
  };

  const handleDeleteBreakingNews = async (newsId) => {
    try {
      if (
        window.confirm(
          "Are you sure you want to banish this headline to the abyss?"
        )
      ) {
        await deleteDoc(doc(db, "breakingNews", newsId));
        fetchBreakingNews(); // Refresh the list after deleting
      }
    } catch (error) {
      console.error(
        "Curse those pesky bugs! Error deleting breaking news:",
        error
      );
      alert(
        "Failed to delete breaking news. Perhaps you angered the code sprites?"
      );
    }
  };

  // Fetch breaking news on component mount
  useEffect(() => {
    fetchBreakingNews();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const storyRef = await addDoc(collection(db, "newsStories"), {
        title: title,
        author: author,
        content: content,
        dateCreated: serverTimestamp(),
      });

      if (isBreaking) {
        await addDoc(collection(db, "breakingNews"), {
          headline: title,
          isActive: true, // You can manage isActive elsewhere
          storyId: storyRef.id,
          dateCreated: serverTimestamp(),
        });
      }

      setTitle("");
      setAuthor("");
      setContent("");
      setIsBreaking(false);
      alert("Story added successfully!");
      fetchBreakingNews(); // Refresh breaking news after adding
    } catch (error) {
      console.error(
        'Error adding story: , "breakingNews while updating the breaking news.'
      );
    }
  };

  const handleImageUploadSuccess = (imageId) => {
    console.log("Image uploaded successfully in ParentComponent! ID:", imageId);
  };

  return (
    <div className="add-story-container p-6 bg-cave-grey rounded-lg shadow-md">
      {/* Breaking News Management Section */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold text-goblin-gold mb-4">
          Breaking News Ticker
        </h2>
        <ul>
          {breakingNews.map((news) => (
            <li key={news.id} className="flex items-center mb-2">
              <span className="text-goblin-gold mr-2">{news.headline}</span>
              <button
                onClick={() =>
                  handleToggleBreakingNews(news.id, !news.isActive)
                }
                className={`px-2 py-1 rounded mr-2 ${news.isActive ? "bg-blood-red text-bone-white" : "bg-green-500 text-black"}`}
              >
                {news.isActive ? "Deactivate" : "Activate"}
              </button>
              <button
                onClick={() => handleDeleteBreakingNews(news.id)}
                className="px-2 py-1 rounded bg-red-500 text-bone-white"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-8">
        <ImageUpload onImageUpload={handleImageUploadSuccess} />
        <ImageGallery />
      </div>
      <h2 className="text-2xl font-bold text-goblin-gold mb-4">
        Conjure a News Story
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="title"
            className="block text-goblin-gold text-sm font-bold mb-2"
          >
            Title:
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div>
          <label
            htmlFor="author"
            className="block text-goblin-gold text-sm font-bold mb-2"
          >
            Author:
          </label>
          <input
            type="text"
            id="author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div>
          <label
            htmlFor="author"
            className="block text-goblin-gold text-sm font-bold mb-2"
          >
            Story Markdown:
          </label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-48"
          />
        </div>
        <div>
          <input
            type="checkbox"
            id="isBreaking"
            checked={isBreaking}
            onChange={(e) => setIsBreaking(e.target.checked)}
          />
          <label htmlFor="isBreaking" className="ml-2 text-goblin-gold">
            BREAKING NEWS!
          </label>
        </div>
        <div>
          <button
            disabled={isLoading}
            className="bg-slime-green hover:bg-goblin-gold text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Publish Story
          </button>
        </div>
      </form>
    </div>
  );
}
