import React, { useState } from "react";
import { db, storage } from "../../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function ImageUpload({ onImageUpload }) {
  const [imageFile, setImageFile] = useState(null);
  const [imageCaption, setImageCaption] = useState("");
  const [imageAltText, setImageAltText] = useState("");

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };
  const handleImageSubmit = async (e) => {
    e.preventDefault();
    try {
      if (imageFile) {
        console.log("Starting image upload process..."); // Log start of upload

        const storageRef = ref(storage, `articleImages/${imageFile.name}`);
        console.log("Uploading to storage ref:", storageRef.fullPath); // Log storage path

        await uploadBytes(storageRef, imageFile);
        console.log("Image uploaded to storage successfully!"); // Log upload success

        const imageURL = await getDownloadURL(storageRef);
        console.log("Image URL retrieved:", imageURL); // Log retrieved URL

        console.log("Adding image data to Firestore...");
        const imageDocRef = await addDoc(collection(db, "articleImages"), {
          imageURL: imageURL,
          caption: imageCaption,
          altText: imageAltText,
          dateAdded: serverTimestamp(),
          relatedStoryIds: [],
          tags: [],
        });
        console.log("Image data added to Firestore with ID:", imageDocRef.id); // Log Firestore write success
        try {
          onImageUpload(imageDocRef.id); // Call the callback function with the uploaded image ID
          setImageFile(null);
          setImageCaption("");
          setImageAltText("");
          alert(`Image uploaded! ID: ${imageDocRef.id}`);
        } catch (error) {
          console.error("well shit", error);
        }
      }
    } catch (error) {
      console.error("Goblin flames! Error uploading image:", error);
      alert("Image upload failed. Did you anger the pixies of the internet?");
    }
  };

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold text-goblin-gold mb-4">
        Upload Images of Chaos
      </h2>
      <form onSubmit={handleImageSubmit} className="text-sm font-bold mb-2">
        <div>
          <label>Image:</label>
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImageUpload}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {/* Caption Input */}
        <div>
          <label
            htmlFor="imageCaption"
            className="block text-goblin-gold text-sm font-bold mb-2"
          >
            Caption (To Entice the Humans):
          </label>
          <input
            type="text"
            id="imageCaption"
            value={imageCaption}
            onChange={(e) => setImageCaption(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {/* Alt Text Input */}
        <div>
          <label
            htmlFor="imageAltText"
            className="block text-goblin-gold text-sm font-bold mb-2"
          >
            Alt Text (For the Blind Humans):
          </label>
          <input
            type="text"
            id="imageAltText"
            value={imageAltText}
            onChange={(e) => setImageAltText(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="px-4 py-2 bg-slime-green hover:bg-goblin-gold text-black font-bold rounded focus:outline-none focus:shadow-outline"
        >
          Upload Image
        </button>
      </form>
    </div>
  );
}
