import React from "react";
import { auth, googleProvider } from "../firebase";
import { signInWithPopup } from "firebase/auth";

const GoogleLogin = () => {
  console.log("GoogleLogin component rendered");

  const signInWithGoogle = async () => {
    try {
      console.log("Signing in with Google");

      await signInWithPopup(auth, googleProvider);
      console.log("Google sign-in successful");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form>
      <button type="button" onClick={signInWithGoogle}>
        Sign in with Google
      </button>
    </form>
  );
};

export default GoogleLogin;
