import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Assuming you have firebase.js set up
import {
  collection,
  onSnapshot,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";

export default function BreakingNewsTicker() {
  const [headlines, setHeadlines] = useState([]); // Start with an empty array

  useEffect(() => {
    // Retrieve the top 3 active headlines from Firebase
    const unsubscribe = onSnapshot(
      query(
        collection(db, "breakingNews"),
        where("isActive", "==", true),
        orderBy("dateCreated", "desc"), // Order by dateCreated to get the most recent ones
        limit(3) // Limit to 3 headlines
      ),
      (snapshot) => {
        const newHeadlines = snapshot.docs.map((doc) => doc.data().headline); // Extract only the headlines
        setHeadlines(newHeadlines);
      }
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <div className="breaking-news-ticker">
      <div className="ticker-content">
        <span className="mr-2">BREAKING NEWS:</span> {/* Added some space */}
        {headlines.map((headline, index) => (
          <span key={index} className="mr-4">
            {headline}
          </span>
        ))}
      </div>
    </div>
  );
}
