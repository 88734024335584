import React from "react";
import { Link } from "react-router-dom"; // For accessible navigation

export default function Banner() {
  return (
    <header className="banner relative flex items-center justify-between p-4 bg-slime-green text-blood-red border-b-4 border-goblin-gold">
      <div className="logo-container flex items-center z-10">
        <img
          src="https://as1.ftcdn.net/v2/jpg/05/43/67/90/1000_F_543679055_GWobFQ4XNYAmiSdJvV5X4rwVg3wHvORo.jpg"
          alt="shiny rock"
          className="w-12 h-12 mr-4 animate-bounce" // Made it smaller and added a bounce animation
        />
        <h1 className="text-3xl font-black tracking-tighter">
          Goblin News Network
        </h1>
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-mold-purple opacity-20 transform -skew-y-6 z-0"></div>
      <div className="absolute top-0 left-0 w-full h-full bg-blood-red opacity-10 transform rotate-2 z-0"></div>

      <div className="search-bar relative z-10">
        <input
          type="text"
          placeholder="Scrounge for News..."
          className="pl-6 pr-3 py-1 rounded-md bg-cave-grey text-goblin-gold placeholder-goblin-gold focus:outline-none focus:ring-2 focus:ring-blood-red"
        />
      </div>
      <nav className="main-navigation z-10">
        <ul className="flex space-x-6 text-lg font-semibold">
          <li className="px-3 py-2 rounded-md">
            {" "}
            {/* Added background and padding */}
            <Link to="/" className="text-slate-900 hover:text-goblin-gold">
              Home
            </Link>
          </li>
          <li className="px-3 py-2 rounded-md">
            <Link to="/world" className="text-slate-900 hover:text-goblin-gold">
              World News
            </Link>
          </li>
          <li className="px-3 py-2 rounded-md">
            <Link
              to="/business"
              className="text-slate-900 hover:text-goblin-gold"
            >
              Business
            </Link>
          </li>
          <li className="px-3 py-2 rounded-md">
            <Link
              to="/technology"
              className="text-slate-900 hover:text-goblin-gold"
            >
              Tech
            </Link>
          </li>
          <li className="px-3 py-2 rounded-md">
            <Link
              to="/sports"
              className="text-slate-900 hover:text-goblin-gold"
            >
              Sports
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
