import React from "react";

export default function Sidebar() {
  return (
    <aside className="sidebar bg-black bg-opacity-80 p-6 rounded-lg shadow-md">
      {/* Goblin-style container */}
      <div className="opinion-section mb-6">
        <h2 className="text-xl font-bold text-goblin-gold mb-4">
          Rants from the Bog
        </h2>
        {/* Opinion pieces will go here */}
        <div className="bg-cave-grey p-4 rounded-md shadow-inner">
          <p className="text-gray-400">
            "Why is the sky blue? Because goblins painted it that way, you
            scruffy oaf!" - Gnarfang, Goblin Philosopher.
          </p>
        </div>
      </div>

      <div className="ads-section mb-6">
        <h2 className="text-xl font-bold text-goblin-gold mb-4">
          Goblin Emporium
        </h2>
        {/* Ads will go here */}
        <div className="bg-cave-grey p-4 rounded-md shadow-inner">
          <p className="text-gray-400">
            Get your shiny new goblin trinkets here! Limited-time offer: buy one
            get one free curse.
          </p>
        </div>
      </div>

      <div>
        <ul>
          <li>How to Properly Skin a Troll</li>
          <li>The Latest in Goblin Fashion (Moss is IN!)</li>
          <li>Top 10 Caves for Avoiding Sunlight</li>
        </ul>
      </div>
    </aside>
  );
}
