import React, { useState, useEffect, useRef, useCallback } from "react";
import { db } from "../firebase";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import { Link } from "react-router-dom";

export default function ContentArea(isAdmin = false, category = "all") {
  const [newsStories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);

  const observer = useRef();

  const fetchInitialStories = useCallback(async () => {
    try {
      const q = query(
        collection(db, "newsStories"),
        orderBy("dateCreated", "desc"),
        limit(3)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const storiesList = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setStories(storiesList);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      });

      return unsubscribe; // Return unsubscribe function for cleanup
    } catch (error) {
      console.error("Error fetching initial stories:", error);
    }
  }, []);

  const loadMoreStories = useCallback(async () => {
    if (isLoading || !lastVisible) return;

    setIsLoading(true);

    try {
      const nextQuery = query(
        collection(db, "newsStories"),
        orderBy("dateCreated", "desc"),
        startAfter(lastVisible),
        limit(3)
      );

      const documentSnapshots = await getDocs(nextQuery);

      const newStories = documentSnapshots.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setStories((prevStories) => [...prevStories, ...newStories]);

      if (documentSnapshots.docs.length > 0) {
        setLastVisible(
          documentSnapshots.docs[documentSnapshots.docs.length - 1]
        );
      } else {
        setLastVisible(null);
      }
    } catch (error) {
      console.error("Error loading more stories:", error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, lastVisible]); // Add isLoading to dependency array

  useEffect(() => {
    const unsubscribe = fetchInitialStories();
    return () => unsubscribe; // Clean up on unmount
  }, [fetchInitialStories]);

  const lastStoryRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreStories();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, loadMoreStories]
  );
  return (
    <main className="content-area bg-cave-grey p-6 rounded-lg overflow-hidden">
      {/* Overflow-hidden for clipping effects! */}

      {/* Goblin-ized Featured Story Section */}
      <div className="featured-story mb-8 bg-deep-shadow p-6 rounded-lg relative overflow-hidden">
        {/* Dark, shadowy background */}
        <img
          src="https://via.placeholder.com/800x400"
          alt="Featured Story"
          className="w-full h-48 object-cover rounded-t-lg transform -skew-y-3"
        />
        {/* Skewed image for that goblin-y tilt! */}
        <div className="absolute bottom-0 left-0 w-full h-2/3 bg-gradient-to-t from-deep-shadow to-transparent"></div>
        {/* Gradient overlay for that mysterious feel */}
        <div className="absolute bottom-4 left-4 text-goblin-gold font-bold text-2xl">
          {/* Contrasting text */}
          Featured Story: {/* Replace with actual story title */}
        </div>
      </div>

      <div className="article-grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {newsStories.map((story, index) => (
          <Link
            to={`/story/${story.id}`}
            key={story.id}
            ref={index === newsStories.length - 1 ? lastStoryRef : null}
            className="article-link block bg-black bg-opacity-80 p-4 rounded-md shadow-lg shadow-goblin-gold transform hover:scale-105 transition duration-300 ease-in-out"
          >
            {/* Wrapped the entire article in a Link for maximum clickability */}
            <img
              src="https://via.placeholder.com/300x200"
              alt="Article Thumbnail"
              className="w-full h-48 object-cover rounded-t-md border-2 border-goblin-gold"
            />
          </Link>
        ))}
      </div>

      {isLoading && (
        <p className="text-goblin-gold text-center mt-4">
          Loading more stories...
        </p>
      )}
    </main>
  );
}
