import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

const EditStoryForm = ({ story, onSave }) => {
  const [editedTitle, setEditedTitle] = useState(story.title);
  const [editedContent, setEditedContent] = useState(story.content);

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setEditedContent(e.target.value);
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = "";
    textarea.style.height = textarea.scrollHeight + "px";
  }, [editedContent]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Saving story:", story);
      const storyRef = doc(db, "newsStories", story.id);
      await updateDoc(storyRef, {
        title: editedTitle,
        content: editedContent,
      });

      onSave({ title: editedTitle, content: editedContent });
    } catch (error) {
      console.error("Error updating story:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          name="title"
          value={editedTitle}
          onChange={handleTitleChange}
          className="text-3xl font-bold text-goblin-gold mb-4 p-2 border rounded w-full bg-cave-grey"
        />
        <textarea
          ref={textareaRef}
          name="content"
          value={editedContent}
          onChange={handleContentChange}
          className="text-bone-white text-lg p-2 border rounded w-full bg-cave-grey resize-none"
        />
        <button
          onClick={handleSubmit}
          className="px-4 py-2 mt-4 bg-goblin-gold text-shadow-black rounded
          hover:bg-gold-glitter"
        >
          Save Changes
        </button>
      </div>
    </form>
  );
};

export default EditStoryForm;
