import React from "react";

export default function Footer() {
  return (
    <footer className="footer p-6 bg-black text-goblin-gold border-t-4 border-slime-green">
      <div className="container mx-auto text-center">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Goblin News Network. All Rights
          Reserved (Maybe). 
        </p>
        <p className="text-xs mt-2">
          Crafted with questionable intentions by{" "}
          <a
            href="https://goblinlife.org/about" /* Replace with your link */
            target="_blank"
            rel="noopener noreferrer"
            className="text-slime-green hover:text-goblin-gold font-semibold"
          >
            Glass Goblin OCDware
          </a>
          .
        </p>
      </div>
    </footer>
  );
}
