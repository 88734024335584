import React, { useEffect } from "react";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import Banner from "./components/Banner";
import BreakingNewsTicker from "./components/BreakingNewsTicker";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

import HomePage from "./pages/HomePage";
import SearchPage from "./pages/SearchPage";
import CategoryPage from "./pages/CategoryPage";
import StoryPage from "./pages/StoryPage";
import EditorPortal from "./pages/EditorPortal";

// auth stuff
import GoogleLogin from "./components/GoogleLogin";
import LogOut from "./components/LogOut";
// if admin, render admin stuff

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";

function App() {
  const [admin, setAdmin] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const ADMIN_UID = "FSZRm1RbZ0d0Mr0bhUxQ2qIJTBJ3";

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("user ID:", user.uid);

        setUser(user);
        setAdmin(user.uid === ADMIN_UID);
      } else {
        setUser(null);
        setAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="app-container">
      <Router>
        <Banner />
        <BreakingNewsTicker />
        <div className="flex flex-col md:flex-row">
          <div className="md:w-3/4 lg:w-4/5 order-2 md:order-1">
            <div className="flex justify-center py-2">
              {!user && <GoogleLogin />}
              {user && <LogOut />}
            </div>
            <div className="flex justify-center py-4">
              {admin && (
                <div className="flex justify-center py-2">
                  <Link
                    to="/addstory"
                    className="px-4 py-2 bg-slime-green rounded-md text-blood-red font-semibold hover:bg-goblin-gold hover:text-deep-shadow transition duration-150 ease-in-out"
                  >
                    Editor Portal
                  </Link>
                </div>
              )}
            </div>
            <Routes>
              <Route path="/" element={HomePage()} />
              <Route
                path="/addstory"
                element={
                  admin ? <EditorPortal isAdmin={admin} /> : <Navigate to="/" />
                }
              />
              <Route
                path="/story/:newsStoryId"
                element={<StoryPage isAdmin={admin} storyId="{:newsStoryId}" />}
              />
              <Route path="/:categoryId" element={CategoryPage} />
              <Route path="/search/:searchTerm" element={SearchPage} />
            </Routes>
          </div>

          <div className="md:w-1/4 lg:w-1/5 order-1 md:order-2">
            <Sidebar />
          </div>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
