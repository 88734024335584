import React from "react";
import { todayDate } from "../util/TodayDate";
import ContentArea from "../components/ContentArea";

export default function HomePage() {
  return (
    <div>
      <h1>GNN Today</h1>
      <h2>{todayDate}</h2>
      <ContentArea />
    </div>
  );
}
